import NavFactory from "@/client/extensions/helpers/navFactory.js";

export default async ({ asyncOps, store }) => {
  // older code - we did not have navFactory then
  let adminNavItemFactoryFunction;
  let navItems;
  try {
    adminNavItemFactoryFunction =
      await require("@/client/assets/static/core/nav/adminSideNavFactory.js");
    navItems = await adminNavItemFactoryFunction.default({
      asyncOps,
      store,
    });
  } catch (e) {
    return {};
  }

  let nav = new NavFactory(navItems);

  let lpTitleItem = {
    action: {
      type: "title",
    },
    label: "lp.admin.sideNav.lpTitle",
  };
  let lpList = {
    icon: "info",
    label: "lp.admin.sideNav.lpList",
    action: {
      type: "routerNavigation",
      data: {
        to: {
          name: "admin-landingPage",
        },
      },
    },
  };
  let mainCategoryItem = nav.getItemChildCopy("entities", "category");

  nav
    .addItemsAfter("dashboard", {
      lpTitleItem,
      lpList,
    })
    .addItemAfter("entities", "mainCategoryItem", mainCategoryItem)
    .removeItems(["ecommerceTitle", "vendor", "brand", "product", "order"]);
  return nav.getItems();
};
