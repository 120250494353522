export default {
  props: {
    sectionData: {
      type: Object,
      default: () => {},
    },
    pageData: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    isNonEmptyString(str) {
      return utilities.isNonEmptyString(str);
    },
    isNonEmptyEditorContent(content) {
      return content && content.blocks && content.blocks.length > 0
    },
    nlToBr(str) {
      return this.textToSafeHtml(str);
    },
    nl2Br(str) {
      return this.textToSafeHtml(str);
    },
    nl2br(str) {
      return this.textToSafeHtml(str);
    },
    onLpMounted() {},
  },
  emits: ['lp:sectionReady'],
  mounted() {
    this.$nextTick(() => {
      this.$emit('lp:sectionReady', this)
    });
  }
};
