export default {
  frameworks: ["we", "animate"],
  frameworkComponents: ["we"],
  scss: ["styles"],
  responsive: {
    classMap: {
      xs: 400,
      s: 500,
      m: 767,
      l: 1024,
      xl: 1300,
    },
    responsiveClassPrefix: "break-",
    responsiveClassMode: "mobileFirst",
    mainMobileBreakPoint: "l",
  },
  defaultIconSet: "rubicons",
  dropdown: {
    animationClass: "animate__fadeInX animate--faster",
    gap: 10,
  },
  weUI: {
    themes: [
      "lead-1",
      "lead-2",
      "lead",
      "lead-4",
      "lead-5",
      "alt-1",
      "alt-2",
      "alt",
      "alt-4",
      "alt-5",
      "bg-1",
      "bg-2",
      "bg",
      "bg-4",
      "bg-5",
      "gray-1",
      "gray-2",
      "gray",
      "gray-4",
      "gray-5",
      "inverse",
      "text",
      "success",
      "warning",
      "error",
      "comp-lead-1",
      "comp-lead-2",
      "comp-lead",
      "comp-lead-4",
      "comp-lead-5",
      "comp-alt-1",
      "comp-alt-2",
      "comp-alt",
      "comp-alt-4",
      "comp-alt-5",
    ],
    notification: {
      defaultCloseAnimation: "fadeOut",
      defaultDuration: 5000,
    },
    button: {
      defaultTheme: "lead",
    },
    tooltip: {
      animationClassIn: "animate__fadeIn",
      animationClassOut: "animate__fadeOut",
      extraClass: "animate--faster",
    },
  },
  pageTransitionClassIn: "animate__fadeIn animate--fast",
  pageTransitionClassOut: "animate__fadeOut  animate--faster",
};
