import asyncOperations from "@/client/extensions/composition/asyncOperations";
import _ from "lodash";
import { ref, reactive } from "vue";

export default (props, store = undefined) => {
  let loadedForms = {};
  const asyncOps = asyncOperations(props, store).asyncOps;

  const isLandingPageFormReady = ref(true);

  const landingPageFormConfig = ref({});

  let allTypes = {};

  const currentFormSections = ref([]);

  const loadSectionTypes = async () => {
    let res = await asyncOps.asyncCall("landingPage/admin/section-types");

    if (res.hasError) {
      warn("error getting section types in useLandingPage", { res });
      return false;
    }

    allTypes = res.data;
  };

  const doesLandingPageFormMatchSectionTypes = (types) => {
    let targetTypes = Array.isArray(types) ? types : [];

    // different length = mismatch
    if (targetTypes.length !== currentFormSections.value.length) {
      return false;
    }

    // check that our types include all the requested types
    return targetTypes.every((v) => currentFormSections.value.includes(v));
  };

  const getDefaultFormConfig = () => {
    return {
      fields: {
        // the type select field
        sectionTypeList: {
          name: "sectionTypeList",
          label: "lp.sections.sectionTypesLabel",
          type: "landingPageSections",
          fields: {
            type: {
              name: "type",
              label: "lp.sections.sectionTypeLabel",
              type: "select2",
              multiple: false,
              list: Object.values(allTypes).map((item) => {
                return {
                  value: item.name,
                  label: item.label,
                };
              }),
              validation: {
                required: {},
              },
              group: "top",
            },
          },
          group: "top",
        },
      },
      // the data mutator handler will remove data that may have been added to the form,
      // if it's corresonding section was removed,
      formHandling: {
        preflight: [
          {
            type: "validation",
            options: {},
          },
          {
            type: "SubmitDataMutation",
            options: {
              handler: ({ form, data, next, fulfil, reject }) => {
                let types = data.sectionTypeList.map((item) => item.type);
                let allTypeNames = Object.keys(allTypes);
                allTypeNames.forEach((possibleType) => {
                  if (!types.includes(possibleType) && data[possibleType]) {
                    delete data[possibleType];
                  }
                });
                next(true);
              },
            },
          },
        ],
      },
      groups: {
        top: {
          component: "block",
          props: {
            class: "margin-2xl-bottom",
          },
        },
        base: {
          component: "CollapsableFieldSet",
          props: {
            title: "lp.sections.baseFieldsetLabel",
            initiallyOpened: false,
          },
        },
      },
    };
  };

  const adjustFormBySectionTypes = async (types = [], force) => {
    let sectionTypeList = types;

    if (
      Array.isArray(types) &&
      types.length > 0 &&
      typeof types[0] !== "string"
    ) {
      sectionTypeList = Object.values(types).map(
        (typeObject) => typeObject.type
      );
    }

    sectionTypeList = sectionTypeList.filter(
      (item) => typeof item === "string" && item !== ""
    );

    if (!force && doesLandingPageFormMatchSectionTypes(sectionTypeList)) {
      return true;
    }

    await loadSectionTypes();

    isLandingPageFormReady.value = false;

    let finalForm = getDefaultFormConfig();

    // load base form
    let baseForm = await loadFormBySource("entity/landingPage/create");
    if (!baseForm) {
      console.log("error getting type form");
    }

    for (const [key, conf] of Object.entries(baseForm.fields)) {
      let targetGroup = conf.group ?? "base";
      finalForm.fields[key] = { ...conf, group: targetGroup };
    }

    for (const [key, conf] of Object.entries(baseForm.groups)) {
      finalForm.groups[key] = { ...conf };
    }

    let addAdjustedFormFromRemote = async (type) => {
      // create group
      finalForm.groups[type] = {
        component: "CollapsableFieldSet",
        props: {
          title: allTypes[type].label,
          initiallyOpened: false,
        },
      };

      // get form
      let typeForm = await loadFormBySource("entity/" + type + "/create");
      if (!typeForm) {
        warn("error getting type form");
      }

      // assign modified fields (with model target)
      for (const [key, conf] of Object.entries(typeForm.fields)) {
        finalForm.fields["type-" + type + key] = {
          ...conf,
          group: type,
          modelTarget: type + "." + conf.name,
        };
      }

      return true;
    }
    let promises = [];
    sectionTypeList.map(type => promises.push(addAdjustedFormFromRemote(type)));
    await Promise.all(promises);


    // merge the forms together
    landingPageFormConfig.value = finalForm;
    isLandingPageFormReady.value = true;
    currentFormSections.value = [...sectionTypeList];
  };

  const loadFormBySource = async (source, data = {}) => {
    let formRequestResult;
    if (loadedForms[source]) {
      return loadedForms[source];
    }
    try {
      formRequestResult = await asyncOps.asyncCall(source, data, {
        method: "get",
      });
    } catch (e) {
      return false;
    }

    if (formRequestResult.isError) {
      return false;
    }

    loadedForms[source] = formRequestResult.data;
    return formRequestResult.data;
  };

  loadSectionTypes();

  return {
    isLandingPageFormReady,
    adjustFormBySectionTypes,
    landingPageFormConfig,
    currentFormSections,
    landingPageForm: reactive({
      isReady: isLandingPageFormReady,
      adjustTypes: adjustFormBySectionTypes,
      config: landingPageFormConfig,
      currentTypes: currentFormSections,
    }),
  };
};
