window.interdeal = {
  sitekey: "3649c7e4e7630b527002495b90ca3099",
  Position: "Right",
  Menulang: "HE",
  domains: {
    js: "https://js.nagich.co.il/",
    acc: "https://access.nagich.co.il/",
  },
  btnStyle: {
    color: {
      main: "#122231",
      second: "#fff",
    },
    vPosition: ["150px", "calc(50vh - 20px"],
    scale: ["0.85", "0.7"], //Size of the widget, the left side is referring to the desktop version, the right side is for mobile.
  },
};

((doc, head, body) => {
  const coreCall = doc.createElement("script");
  coreCall.src = "https://js.nagich.co.il/core/2.1.10/accessibility.js";
  coreCall.defer = true;
  coreCall.integrity =
    "sha512-PWvjZ/e9EGeHEPppEOqscViKxQIW1/5rJeFedXLJiv0IrltJPvuhrYOiUsOkQ49FaZ5HSDp51/O/6V9uL2nNIA==";
  coreCall.crossOrigin = "anonymous";
  coreCall.setAttribute("data-cfasync", true);
  body ? body.appendChild(coreCall) : head.appendChild(coreCall);
})(document, document.head, document.body);
