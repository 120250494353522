module.exports = {
  name: "מיטב - עמודי נחיתה",
  iconPaths: {
    favicon32: "meitavlp/images/favicons/favicon-32x32.png",
    favicon16: "meitavlp/images/favicons/favicon-16x16.png",
    appleTouchIcon: "meitavlp/images/favicons/apple-touch-icon-152x152.png",
    maskIcon: "meitavlp/images/favicons/safari-pinned-tab.svg",
    msTileImage: "meitavlp/images/favicons/msapplication-icon-144x144.png",
  },
  themeColor: "#1C7D64",
};
